import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {map} from 'rxjs/operators';
import {BookingDialogType} from './booking-dialog-type.enum';
import {Observable} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserPackage, UserPackageControllerService} from '@api';
import {UserService} from '@common/user.service';

@Component({
  selector: 'app-booking-dialog',
  templateUrl: './booking-dialog.component.html',
  styleUrls: ['./booking-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookingDialogComponent implements OnInit {
  packages$: Observable<UserPackage[]>;
  userId$: Observable<number>;

  readonly BookingDialogType = BookingDialogType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {type: BookingDialogType},
    public dialogRef: MatDialogRef<BookingDialogComponent>,
    private packageApiService: UserPackageControllerService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.packages$ = this.packageApiService.getAllUserPackages().pipe(
      map((packages: UserPackage[]) => {
        const bookablePackages = packages.filter(pkg => pkg.bookable);

        if (this.data.type === BookingDialogType.Uploads) {
          return bookablePackages.filter(pkg => pkg.groupName === 'package_group.upload');
        }
        if (this.data.type === BookingDialogType.Tickets) {
          return bookablePackages.filter(pkg => pkg.groupName === 'package_group.ticket');
        }

        return bookablePackages;
      })
    );

    this.userId$ = this.userService.currentUser.pipe(
      map((user) => user?.id)
    );
  }
}
