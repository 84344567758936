/**
 * AP Software
 * AP Software REST API.
 *
 * OpenAPI spec version: 3.8.2
 * Contact: support@ap-software.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BookedUserPackageDiscount } from './bookedUserPackageDiscount';
import { UserConstraints } from './userConstraints';

export interface UserPackage { 
    readonly id?: number;
    version?: number;
    created?: Date;
    updated?: Date;
    groupName: string;
    name: string;
    displayName: string;
    constraints: UserConstraints;
    durationDays?: number;
    originalPriceCents?: number;
    bookable?: boolean;
    billingType?: UserPackage.BillingTypeEnum;
    actualPriceCents?: number;
    discounts?: Array<BookedUserPackageDiscount>;
    identifier?: string;
    readonly eTag?: string;
}
export namespace UserPackage {
    export type BillingTypeEnum = 'MONTHLY' | 'ONCE';
    export const BillingTypeEnum = {
        MONTHLY: 'MONTHLY' as BillingTypeEnum,
        ONCE: 'ONCE' as BillingTypeEnum
    };
}