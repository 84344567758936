export namespace TicketActions {
  export class EditComment {
    static readonly type = '[Ticket] Edit Comment';
    constructor(public commentId: number) {}
  }

  export class SaveComment {
    static readonly type = '[Ticket] Save Comment';
  }

  export class SetCommentBody {
    static readonly type = '[Ticket] Set Comment Body';
    constructor(public body: string) {}
  }
}
