import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {TicketActions} from '../actions/ticket.actions';

export interface TicketStateModel {
  editComment: {
    id: number;
    newBody: string;
  } | null;
}

@State<TicketStateModel>({
  name: 'ticket',
  defaults: {
    editComment: null,
  }
})
@Injectable()
export class TicketState {
  @Selector()
  static editingComment(state: TicketStateModel) {
    return state.editComment;
  }

  @Action(TicketActions.EditComment)
  editComment(ctx: StateContext<TicketStateModel>, action: TicketActions.EditComment) {
    ctx.setState(state => ({
      ...state,
      editComment: {
        id: action.commentId,
        newBody: null,
      },
    }));
  }

  @Action(TicketActions.SaveComment)
  saveComment(ctx: StateContext<TicketStateModel>) {
    ctx.setState(state => ({
      ...state,
      editComment: null,
    }));
  }

  @Action(TicketActions.SetCommentBody)
  fetchCustomers(ctx: StateContext<TicketStateModel>, action: TicketActions.SetCommentBody) {
    ctx.setState(state => ({
      ...state,
      editComment: {
        ...state.editComment,
        newBody: action.body,
      },
    }));
  }
}
