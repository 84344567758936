import {Injectable} from '@angular/core';
// import {RememberMeToken} from '@api';
import {LocalStorageService} from '@common/local-storage.service';

class RememberMeToken {}

@Injectable()
export class RememberMeService {
  private readonly rememberMeKey = 'rememberMeToken';
  public readonly rememberMeHeader = 'Remember-Me-Token';

  constructor(private readonly localStorage: LocalStorageService) {}

  getRememberMeToken(): string | null {
    return this.localStorage.getItem(this.rememberMeKey);
  }

  setRememberMeToken(token: RememberMeToken) {
    this.localStorage.setItem(this.rememberMeKey, JSON.stringify(token));
  }

  clearRememberMeToken(): void {
    this.localStorage.removeItem(this.rememberMeKey);
  }
}
