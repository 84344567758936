/**
 * AP Software
 * AP Software REST API.
 *
 * OpenAPI spec version: 3.8.2
 * Contact: support@ap-software.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Comment } from './comment';
import { DataObject } from './dataObject';
import { Team } from './team';

export interface Document { 
    readonly id?: number;
    version?: number;
    created?: Date;
    updated?: Date;
    name?: string;
    text?: string;
    index?: number;
    documentStatus?: Document.DocumentStatusEnum;
    attachments?: Array<DataObject>;
    uploadedFiles?: Array<DataObject>;
    comments?: Array<Comment>;
    team?: Team;
    readonly eTag?: string;
}
export namespace Document {
    export type DocumentStatusEnum = 'UNDECIDED' | 'ACCEPTED' | 'DENIED';
    export const DocumentStatusEnum = {
        UNDECIDED: 'UNDECIDED' as DocumentStatusEnum,
        ACCEPTED: 'ACCEPTED' as DocumentStatusEnum,
        DENIED: 'DENIED' as DocumentStatusEnum
    };
}