import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, EMPTY, Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {UserSessionService} from './user-session.service';
import {Router} from '@angular/router';
import {catchError, filter, map, skipWhile, tap} from 'rxjs/operators';
import {Session, SessionControllerService, SessionInformation, User} from '@api';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {RememberMeService} from '@common/remember-me.service';

@Injectable()
export class UserService {

  public readonly sessionInformation: BehaviorSubject<SessionInformation> = new BehaviorSubject(null);
  public sessionValidated = false;
  private readonly _currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public readonly currentUser: Observable<User> = this._currentUser.asObservable();
  public readonly currentSession: Observable<Session>;
  private readonly currentProfilePicture: BehaviorSubject<SafeUrl> = new BehaviorSubject<SafeUrl>(null);
  public readonly currentProfilePicture$: Observable<SafeUrl> = this.currentProfilePicture.asObservable();

  constructor(private sessionApiService: SessionControllerService,
              private userSessionService: UserSessionService,
              private router: Router,
              private httpClient: HttpClient,
              private domSanitizer: DomSanitizer,
              private rememberMeService: RememberMeService,
              @Inject('domain') private domain: string) {
    // Build transformed observables
    this.currentSession = this.sessionInformation.pipe(
      filter(Boolean),
      map((sessionInformation: SessionInformation) => sessionInformation.session)
    );

    // Update the session in UserSessionService accordingly
    this.currentSession.subscribe((currentSession: Session) => {
      this.userSessionService.session = currentSession;
    });

    // Update local storage whenever session information changes
    this.sessionInformation.pipe(
      skipWhile(() => !this.sessionValidated)
    ).subscribe(() => {
      this.saveSessionToLocalStorage();
    });

    this.sessionInformation.pipe(
      filter(Boolean),
      map((sessionInformation: SessionInformation) => sessionInformation.user)
    ).subscribe((user: User) => this._currentUser.next(user));

    this._currentUser.pipe(
      filter(Boolean)
    ).subscribe((user: User) => this.loadProfilePicture(user));
  }

  public login(username: string, password: string, rememberMe: boolean): Observable<boolean> {
    const credentials = {
      login: username,
      password: password
    };
    return this.sessionApiService.openSession(credentials, 'response').pipe(
      map((res: HttpResponse<SessionInformation>) => {
        if (res.ok) {
          this.sessionValidated = true;
          this.sessionInformation.next(res.body);
          // if (res.body.rememberMeToken) {
          //   this.rememberMeService.setRememberMeToken(res.body.rememberMeToken);
          // }
        }
        return res.ok;
      })
    );
  }

  /**
   * Logout the current user
   *
   * @returns void
   */
  public logout(): void {
    this.sessionApiService.closeSession('response').subscribe();
    this.removeSessionFromLocalStorage();
    this.sessionInformation.next(null);
    this.rememberMeService.clearRememberMeToken();
    this.router.navigate(['/login']).then();
  }

  /**
   * Fetches the current session without providing credentials
   *
   * @returns {Observable<boolean>}
   */
  public fetchSession(): Observable<void> {
    return this.sessionApiService.getSession('response').pipe(
      tap((res: HttpResponse<SessionInformation>) => {
        if (res.ok) {
          this.sessionValidated = true;
          this.sessionInformation.next(res.body);
        }
      }),
      map(() => {}),
      catchError(() => {
        this.removeSessionFromLocalStorage();
        return EMPTY;
      })
    );
  }

  /**
   * Tries to load session information from local storage
   */
  public loadSessionFromLocalStorage(): Promise<boolean> {
    if (localStorage) {
      try {
        const sessionStr = localStorage.getItem('session');
        const session = sessionStr ? JSON.parse(sessionStr) : null;
        if (session) {
          this.sessionInformation.next(session);
          return Promise.resolve(true);
        }
      } catch (_) {
        return Promise.reject(false);
      }
    }
    return Promise.reject(false);
  }

  public removeSessionFromLocalStorage(): void {
    if (localStorage) {
      try {
        localStorage.removeItem('session');
      } catch (_) {}
    }
    this.sessionValidated = false;
  }

  /**
   * Saves the current session information to local storage
   */
  private saveSessionToLocalStorage(): void {
    if (localStorage) {
      const session = JSON.stringify(this.sessionInformation.getValue());
      try {
        localStorage.setItem('session', session);
      } catch (_) {}
    }
  }

  public reloadProfilePicture(): void {
    this.loadProfilePicture(this._currentUser.getValue());
  }

  private loadProfilePicture(user: User): void {
    if (user) {
      this.httpClient.get(this.domain + '/common/users/' + user.id + '/image', { responseType: 'blob' }).pipe(
        map((e: Blob) => (e && e.size > 0) ? this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e)) : null)
      ).subscribe({
        next: (url: SafeUrl) => {
          this.currentProfilePicture.next(url);
        },
        error: (e) => {
          console.log(e);
          this.currentProfilePicture.next(null);
        }
      });
    } else {
      this.currentProfilePicture.next(null);
    }
  }
}
