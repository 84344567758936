/**
 * AP Software
 * AP Software REST API.
 *
 * OpenAPI spec version: 3.8.2
 * Contact: support@ap-software.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Customer } from '../model/customer';
import { CustomerImportResult } from '../model/customerImportResult';
import { CustomerSummary } from '../model/customerSummary';
import { FieldModification } from '../model/fieldModification';
import { ImportCsvBody } from '../model/importCsvBody';
import { ImportXlsxBody } from '../model/importXlsxBody';
import { LongEntityBase } from '../model/longEntityBase';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CustomerControllerService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Creates a new entity based on the given data.
     * 
     * @param body 
     * @param ifMatch 
     * @param ifUnmodifiedSince 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCustomer(body: Customer, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public createCustomer(body: Customer, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public createCustomer(body: Customer, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public createCustomer(body: Customer, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createCustomer.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Customer>('post',`${this.basePath}/common/customers/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes an entity.
     * 
     * @param id 
     * @param ifMatch 
     * @param ifUnmodifiedSince 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCustomer(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteCustomer(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteCustomer(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteCustomer(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteCustomer.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/common/customers/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns an example CSV file with all customer data for given team.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateExampleCSV(observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public generateExampleCSV(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public generateExampleCSV(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public generateExampleCSV(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/csv'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<string>>('get',`${this.basePath}/common/customers/example-csv`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns an example XLSX file with all customer data for given team.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateExampleXLSX(observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public generateExampleXLSX(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public generateExampleXLSX(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public generateExampleXLSX(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<string>>('get',`${this.basePath}/common/customers/example-xlsx`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Read all visible entities. If the user is not allowed to read any entity, an empty list will be returned.
     * 
     * @param offset 
     * @param limit 
     * @param team 
     * @param ifNoneMatch 
     * @param ifModifiedSince 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllCustomers(offset?: number, limit?: number, team?: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<Array<LongEntityBase>>;
    public getAllCustomers(offset?: number, limit?: number, team?: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LongEntityBase>>>;
    public getAllCustomers(offset?: number, limit?: number, team?: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LongEntityBase>>>;
    public getAllCustomers(offset?: number, limit?: number, team?: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (team !== undefined && team !== null) {
            queryParameters = queryParameters.set('team', <any>team);
        }

        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }
        if (ifModifiedSince !== undefined && ifModifiedSince !== null) {
            headers = headers.set('If-Modified-Since', String(ifModifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<LongEntityBase>>('get',`${this.basePath}/common/customers/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Read an entity based on the given id.
     * 
     * @param id 
     * @param ifNoneMatch 
     * @param ifModifiedSince 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomer(id: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public getCustomer(id: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public getCustomer(id: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public getCustomer(id: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getCustomer.');
        }



        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }
        if (ifModifiedSince !== undefined && ifModifiedSince !== null) {
            headers = headers.set('If-Modified-Since', String(ifModifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Customer>('get',`${this.basePath}/common/customers/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns an overview of all customers known to the team.
     * 
     * @param teamId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCustomersOverview(teamId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<CustomerSummary>>;
    public getCustomersOverview(teamId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CustomerSummary>>>;
    public getCustomersOverview(teamId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CustomerSummary>>>;
    public getCustomersOverview(teamId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (teamId === null || teamId === undefined) {
            throw new Error('Required parameter teamId was null or undefined when calling getCustomersOverview.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (teamId !== undefined && teamId !== null) {
            queryParameters = queryParameters.set('teamId', <any>teamId);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CustomerSummary>>('get',`${this.basePath}/common/customers/overview`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Imports or updates customers from a CSV file.
     * 
     * @param teamId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importCustomersCsv(teamId: number, body?: ImportCsvBody, observe?: 'body', reportProgress?: boolean): Observable<CustomerImportResult>;
    public importCustomersCsv(teamId: number, body?: ImportCsvBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerImportResult>>;
    public importCustomersCsv(teamId: number, body?: ImportCsvBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerImportResult>>;
    public importCustomersCsv(teamId: number, body?: ImportCsvBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (teamId === null || teamId === undefined) {
            throw new Error('Required parameter teamId was null or undefined when calling importCustomersCsv.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (teamId !== undefined && teamId !== null) {
            queryParameters = queryParameters.set('teamId', <any>teamId);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CustomerImportResult>('post',`${this.basePath}/common/customers/import/csv`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Imports or updates customers from a XLSX file.
     * 
     * @param teamId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public importCustomersXlsx(teamId: number, body?: ImportXlsxBody, observe?: 'body', reportProgress?: boolean): Observable<CustomerImportResult>;
    public importCustomersXlsx(teamId: number, body?: ImportXlsxBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CustomerImportResult>>;
    public importCustomersXlsx(teamId: number, body?: ImportXlsxBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CustomerImportResult>>;
    public importCustomersXlsx(teamId: number, body?: ImportXlsxBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (teamId === null || teamId === undefined) {
            throw new Error('Required parameter teamId was null or undefined when calling importCustomersXlsx.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (teamId !== undefined && teamId !== null) {
            queryParameters = queryParameters.set('teamId', <any>teamId);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CustomerImportResult>('post',`${this.basePath}/common/customers/import/xlsx`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an entity based on the given data.
     * 
     * @param body 
     * @param id 
     * @param ifMatch 
     * @param ifUnmodifiedSince 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public overwriteCustomer(body: Customer, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public overwriteCustomer(body: Customer, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public overwriteCustomer(body: Customer, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public overwriteCustomer(body: Customer, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling overwriteCustomer.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling overwriteCustomer.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Customer>('put',`${this.basePath}/common/customers/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an entity based on the given modifications. The modified entity is returned.
     * 
     * @param body 
     * @param id 
     * @param ifMatch 
     * @param ifUnmodifiedSince 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchCustomer(body: Array<FieldModification>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<Customer>;
    public patchCustomer(body: Array<FieldModification>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Customer>>;
    public patchCustomer(body: Array<FieldModification>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Customer>>;
    public patchCustomer(body: Array<FieldModification>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling patchCustomer.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling patchCustomer.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Customer>('patch',`${this.basePath}/common/customers/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
