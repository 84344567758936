/**
 * AP Software
 * AP Software REST API.
 *
 * OpenAPI spec version: 3.8.2
 * Contact: support@ap-software.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DocumentGroup } from '../model/documentGroup';
import { FieldModification } from '../model/fieldModification';
import { IndexUpdateSetEntry } from '../model/indexUpdateSetEntry';
import { LongEntityBase } from '../model/longEntityBase';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DocumentGroupControllerService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param documentGroupId 
     * @param targetTemplateId 
     * @param targetUploadId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cloneDocumentGroup(documentGroupId: number, targetTemplateId?: number, targetUploadId?: number, observe?: 'body', reportProgress?: boolean): Observable<DocumentGroup>;
    public cloneDocumentGroup(documentGroupId: number, targetTemplateId?: number, targetUploadId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentGroup>>;
    public cloneDocumentGroup(documentGroupId: number, targetTemplateId?: number, targetUploadId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentGroup>>;
    public cloneDocumentGroup(documentGroupId: number, targetTemplateId?: number, targetUploadId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentGroupId === null || documentGroupId === undefined) {
            throw new Error('Required parameter documentGroupId was null or undefined when calling cloneDocumentGroup.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (documentGroupId !== undefined && documentGroupId !== null) {
            queryParameters = queryParameters.set('documentGroupId', <any>documentGroupId);
        }
        if (targetTemplateId !== undefined && targetTemplateId !== null) {
            queryParameters = queryParameters.set('targetTemplateId', <any>targetTemplateId);
        }
        if (targetUploadId !== undefined && targetUploadId !== null) {
            queryParameters = queryParameters.set('targetUploadId', <any>targetUploadId);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentGroup>('post',`${this.basePath}/upload/documentgroups/clone`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new entity based on the given data.
     * 
     * @param body 
     * @param ifMatch 
     * @param ifUnmodifiedSince 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createDocumentGroup(body: DocumentGroup, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<DocumentGroup>;
    public createDocumentGroup(body: DocumentGroup, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentGroup>>;
    public createDocumentGroup(body: DocumentGroup, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentGroup>>;
    public createDocumentGroup(body: DocumentGroup, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createDocumentGroup.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DocumentGroup>('post',`${this.basePath}/upload/documentgroups/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes an entity.
     * 
     * @param id 
     * @param ifMatch 
     * @param ifUnmodifiedSince 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDocumentGroup(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteDocumentGroup(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteDocumentGroup(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteDocumentGroup(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteDocumentGroup.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/upload/documentgroups/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Read all visible entities. If the user is not allowed to read any entity, an empty list will be returned.
     * 
     * @param offset 
     * @param limit 
     * @param team 
     * @param ifNoneMatch 
     * @param ifModifiedSince 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllDocumentGroups(offset?: number, limit?: number, team?: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<Array<LongEntityBase>>;
    public getAllDocumentGroups(offset?: number, limit?: number, team?: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LongEntityBase>>>;
    public getAllDocumentGroups(offset?: number, limit?: number, team?: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LongEntityBase>>>;
    public getAllDocumentGroups(offset?: number, limit?: number, team?: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (team !== undefined && team !== null) {
            queryParameters = queryParameters.set('team', <any>team);
        }

        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }
        if (ifModifiedSince !== undefined && ifModifiedSince !== null) {
            headers = headers.set('If-Modified-Since', String(ifModifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<LongEntityBase>>('get',`${this.basePath}/upload/documentgroups/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Read an entity based on the given id.
     * 
     * @param id 
     * @param ifNoneMatch 
     * @param ifModifiedSince 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDocumentGroup(id: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<DocumentGroup>;
    public getDocumentGroup(id: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentGroup>>;
    public getDocumentGroup(id: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentGroup>>;
    public getDocumentGroup(id: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDocumentGroup.');
        }



        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }
        if (ifModifiedSince !== undefined && ifModifiedSince !== null) {
            headers = headers.set('If-Modified-Since', String(ifModifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentGroup>('get',`${this.basePath}/upload/documentgroups/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an entity based on the given data.
     * 
     * @param body 
     * @param id 
     * @param ifMatch 
     * @param ifUnmodifiedSince 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public overwriteDocumentGroup(body: DocumentGroup, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<DocumentGroup>;
    public overwriteDocumentGroup(body: DocumentGroup, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentGroup>>;
    public overwriteDocumentGroup(body: DocumentGroup, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentGroup>>;
    public overwriteDocumentGroup(body: DocumentGroup, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling overwriteDocumentGroup.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling overwriteDocumentGroup.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DocumentGroup>('put',`${this.basePath}/upload/documentgroups/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an entity based on the given modifications. The modified entity is returned.
     * 
     * @param body 
     * @param id 
     * @param ifMatch 
     * @param ifUnmodifiedSince 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchDocumentGroup(body: Array<FieldModification>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<DocumentGroup>;
    public patchDocumentGroup(body: Array<FieldModification>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentGroup>>;
    public patchDocumentGroup(body: Array<FieldModification>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentGroup>>;
    public patchDocumentGroup(body: Array<FieldModification>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling patchDocumentGroup.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling patchDocumentGroup.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DocumentGroup>('patch',`${this.basePath}/upload/documentgroups/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateIndexes1(body: Array<IndexUpdateSetEntry>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateIndexes1(body: Array<IndexUpdateSetEntry>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateIndexes1(body: Array<IndexUpdateSetEntry>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateIndexes1(body: Array<IndexUpdateSetEntry>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateIndexes1.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/upload/documentgroups/actions/updateIndexes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
