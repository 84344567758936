/**
 * AP Software
 * AP Software REST API.
 *
 * OpenAPI spec version: 3.8.2
 * Contact: support@ap-software.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TicketQuery { 
    teamIds?: Array<number>;
    assigneeIds?: Array<number>;
    waiting?: boolean;
    resolved?: boolean;
    authorIds?: Array<number>;
    minCreation?: Date;
    maxCreation?: Date;
    searchTerm?: string;
    subjectText?: string;
    bodyText?: string;
    statusIds?: Array<number>;
    minPriority?: number;
    maxPriority?: number;
    categoryIds?: Array<number>;
    minAlarm?: Date;
    maxAlarm?: Date;
    hasAlarm?: boolean;
    minDeadline?: Date;
    maxDeadline?: Date;
    hasDeadline?: boolean;
    minDate?: Date;
    maxDate?: Date;
    customerIds?: Array<number>;
    watcherIds?: Array<number>;
    orderBy?: TicketQuery.OrderByEnum;
    orderDescending?: boolean;
}
export namespace TicketQuery {
    export type OrderByEnum = 'TEAM' | 'ASSIGNEE' | 'AUTHOR' | 'CREATED' | 'UPDATED' | 'ALARM' | 'DEADLINE' | 'SUBJECT' | 'BODY' | 'STATUS' | 'PRIORITY' | 'CATEGORY' | 'CUSTOMERS';
    export const OrderByEnum = {
        TEAM: 'TEAM' as OrderByEnum,
        ASSIGNEE: 'ASSIGNEE' as OrderByEnum,
        AUTHOR: 'AUTHOR' as OrderByEnum,
        CREATED: 'CREATED' as OrderByEnum,
        UPDATED: 'UPDATED' as OrderByEnum,
        ALARM: 'ALARM' as OrderByEnum,
        DEADLINE: 'DEADLINE' as OrderByEnum,
        SUBJECT: 'SUBJECT' as OrderByEnum,
        BODY: 'BODY' as OrderByEnum,
        STATUS: 'STATUS' as OrderByEnum,
        PRIORITY: 'PRIORITY' as OrderByEnum,
        CATEGORY: 'CATEGORY' as OrderByEnum,
        CUSTOMERS: 'CUSTOMERS' as OrderByEnum
    };
}