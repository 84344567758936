<h1 matDialogTitle>Release Notes</h1>

<div class="version" *ngFor="let version of versions">
  <h3 class="version__name">{{version.version}}</h3>

  <span *ngIf="!version.features?.length && !version.bugfixes?.length">
    Diverse Verbesserungen und Fehlerbehebungen.
  </span>

  <ng-container *ngIf="version.features?.length">
    <h4 class="version__subtitle">Features</h4>
    <ul class="version__list">
      <li *ngFor="let feature of version.features">{{feature}}</li>
    </ul>
  </ng-container>

  <ng-container *ngIf="version.bugfixes?.length">
    <h4 class="version__subtitle">Bugfixes</h4>
    <ul class="version__list">
      <li *ngFor="let bugfix of version.bugfixes">{{bugfix}}</li>
    </ul>
  </ng-container>
</div>
