import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  getItem(key: string): string | null {
    if (!localStorage) {
      return null;
    }

    try {
      return localStorage.getItem(key);
    } catch (_) {
      return null;
    }
  }

  setItem(key: string, value: string): boolean {
    if (!localStorage) {
      return false;
    }

    try {
      localStorage.setItem(key, value);
      return true;
    } catch (_) {
      return false;
    }
  }

  removeItem(key: string): boolean {
    if (!localStorage) {
      return false;
    }

    try {
      localStorage.removeItem(key);
      return true;
    } catch (_) {
      return false;
    }
  }
}
