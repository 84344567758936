/**
 * AP Software
 * AP Software REST API.
 *
 * OpenAPI spec version: 3.8.2
 * Contact: support@ap-software.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ActionsAddAttachmentBody1 } from '../model/actionsAddAttachmentBody1';
import { DataObject } from '../model/dataObject';
import { FieldModificationDefault } from '../model/fieldModificationDefault';
import { LongEntityBase } from '../model/longEntityBase';
import { Modification } from '../model/modification';
import { Ticket } from '../model/ticket';
import { TicketDefault } from '../model/ticketDefault';
import { TicketFilteredDto } from '../model/ticketFilteredDto';
import { TicketQuery } from '../model/ticketQuery';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TicketControllerService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param ticketId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addAttachment(ticketId: number, body?: ActionsAddAttachmentBody1, observe?: 'body', reportProgress?: boolean): Observable<DataObject>;
    public addAttachment(ticketId: number, body?: ActionsAddAttachmentBody1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DataObject>>;
    public addAttachment(ticketId: number, body?: ActionsAddAttachmentBody1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DataObject>>;
    public addAttachment(ticketId: number, body?: ActionsAddAttachmentBody1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ticketId === null || ticketId === undefined) {
            throw new Error('Required parameter ticketId was null or undefined when calling addAttachment.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ticketId !== undefined && ticketId !== null) {
            queryParameters = queryParameters.set('ticketId', <any>ticketId);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DataObject>('post',`${this.basePath}/tickets/actions/addAttachment`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param ticketId 
     * @param commentBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public commentAsWatcher(ticketId: number, commentBody: string, observe?: 'body', reportProgress?: boolean): Observable<Ticket>;
    public commentAsWatcher(ticketId: number, commentBody: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Ticket>>;
    public commentAsWatcher(ticketId: number, commentBody: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Ticket>>;
    public commentAsWatcher(ticketId: number, commentBody: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ticketId === null || ticketId === undefined) {
            throw new Error('Required parameter ticketId was null or undefined when calling commentAsWatcher.');
        }

        if (commentBody === null || commentBody === undefined) {
            throw new Error('Required parameter commentBody was null or undefined when calling commentAsWatcher.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ticketId !== undefined && ticketId !== null) {
            queryParameters = queryParameters.set('ticketId', <any>ticketId);
        }
        if (commentBody !== undefined && commentBody !== null) {
            queryParameters = queryParameters.set('commentBody', <any>commentBody);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Ticket>('post',`${this.basePath}/tickets/actions/commentAsWatcher`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new entity based on the given data.
     * 
     * @param body 
     * @param ifMatch 
     * @param ifUnmodifiedSince 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTicket(body: Ticket, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<Ticket>;
    public createTicket(body: Ticket, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Ticket>>;
    public createTicket(body: Ticket, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Ticket>>;
    public createTicket(body: Ticket, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createTicket.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Ticket>('post',`${this.basePath}/tickets/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes an entity.
     * 
     * @param id 
     * @param ifMatch 
     * @param ifUnmodifiedSince 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTicket(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteTicket(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteTicket(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteTicket(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteTicket.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/tickets/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns a filtered list of tickets.
     * 
     * @param body 
     * @param ifNoneMatch 
     * @param ifModifiedSince 
     * @param limit 
     * @param offset 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public filterTickets(body: TicketQuery, ifNoneMatch?: string, ifModifiedSince?: Date, limit?: number, offset?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TicketFilteredDto>>;
    public filterTickets(body: TicketQuery, ifNoneMatch?: string, ifModifiedSince?: Date, limit?: number, offset?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TicketFilteredDto>>>;
    public filterTickets(body: TicketQuery, ifNoneMatch?: string, ifModifiedSince?: Date, limit?: number, offset?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TicketFilteredDto>>>;
    public filterTickets(body: TicketQuery, ifNoneMatch?: string, ifModifiedSince?: Date, limit?: number, offset?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling filterTickets.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }

        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }
        if (ifModifiedSince !== undefined && ifModifiedSince !== null) {
            headers = headers.set('If-Modified-Since', String(ifModifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<TicketFilteredDto>>('post',`${this.basePath}/tickets/filtered`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Read all visible entities. If the user is not allowed to read any entity, an empty list will be returned.
     * 
     * @param offset 
     * @param limit 
     * @param team 
     * @param ifNoneMatch 
     * @param ifModifiedSince 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllTickets(offset?: number, limit?: number, team?: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<Array<LongEntityBase>>;
    public getAllTickets(offset?: number, limit?: number, team?: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LongEntityBase>>>;
    public getAllTickets(offset?: number, limit?: number, team?: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LongEntityBase>>>;
    public getAllTickets(offset?: number, limit?: number, team?: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (team !== undefined && team !== null) {
            queryParameters = queryParameters.set('team', <any>team);
        }

        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }
        if (ifModifiedSince !== undefined && ifModifiedSince !== null) {
            headers = headers.set('If-Modified-Since', String(ifModifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<LongEntityBase>>('get',`${this.basePath}/tickets/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param ifNoneMatch 
     * @param ifModifiedSince 
     * @param offset 
     * @param limit 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getModifications(id: number, ifNoneMatch?: string, ifModifiedSince?: Date, offset?: number, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Modification>>;
    public getModifications(id: number, ifNoneMatch?: string, ifModifiedSince?: Date, offset?: number, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Modification>>>;
    public getModifications(id: number, ifNoneMatch?: string, ifModifiedSince?: Date, offset?: number, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Modification>>>;
    public getModifications(id: number, ifNoneMatch?: string, ifModifiedSince?: Date, offset?: number, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getModifications.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }
        if (ifModifiedSince !== undefined && ifModifiedSince !== null) {
            headers = headers.set('If-Modified-Since', String(ifModifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Modification>>('get',`${this.basePath}/tickets/${encodeURIComponent(String(id))}/modifications`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Read an entity based on the given id.
     * 
     * @param id 
     * @param ifNoneMatch 
     * @param ifModifiedSince 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTicket(id: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<Ticket>;
    public getTicket(id: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Ticket>>;
    public getTicket(id: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Ticket>>;
    public getTicket(id: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getTicket.');
        }



        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }
        if (ifModifiedSince !== undefined && ifModifiedSince !== null) {
            headers = headers.set('If-Modified-Since', String(ifModifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Ticket>('get',`${this.basePath}/tickets/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an entity based on the given modifications. The modified entity is returned.
     * 
     * @param body 
     * @param id 
     * @param ifMatch 
     * @param ifUnmodifiedSince 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modify(body: Array<FieldModificationDefault>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<TicketDefault>;
    public modify(body: Array<FieldModificationDefault>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TicketDefault>>;
    public modify(body: Array<FieldModificationDefault>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TicketDefault>>;
    public modify(body: Array<FieldModificationDefault>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling modify.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling modify.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TicketDefault>('patch',`${this.basePath}/tickets/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an entity based on the given data.
     * 
     * @param body 
     * @param id 
     * @param ifMatch 
     * @param ifUnmodifiedSince 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public overwriteTicket(body: Ticket, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<Ticket>;
    public overwriteTicket(body: Ticket, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Ticket>>;
    public overwriteTicket(body: Ticket, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Ticket>>;
    public overwriteTicket(body: Ticket, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling overwriteTicket.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling overwriteTicket.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Ticket>('put',`${this.basePath}/tickets/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public returnsAllTicketsWatchedByCurrentUser(observe?: 'body', reportProgress?: boolean): Observable<Array<Ticket>>;
    public returnsAllTicketsWatchedByCurrentUser(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Ticket>>>;
    public returnsAllTicketsWatchedByCurrentUser(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Ticket>>>;
    public returnsAllTicketsWatchedByCurrentUser(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Ticket>>('get',`${this.basePath}/tickets/watched`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param ticketId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unsubscribeAsWatcher(ticketId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public unsubscribeAsWatcher(ticketId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public unsubscribeAsWatcher(ticketId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public unsubscribeAsWatcher(ticketId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ticketId === null || ticketId === undefined) {
            throw new Error('Required parameter ticketId was null or undefined when calling unsubscribeAsWatcher.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ticketId !== undefined && ticketId !== null) {
            queryParameters = queryParameters.set('ticketId', <any>ticketId);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/tickets/actions/unsubscribeAsWatcher`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
