/**
 * AP Software
 * AP Software REST API.
 *
 * OpenAPI spec version: 3.8.2
 * Contact: support@ap-software.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { FieldModification } from '../model/fieldModification';
import { IdImageBody } from '../model/idImageBody';
import { LongEntityBase } from '../model/longEntityBase';
import { PasswordChangeRequest } from '../model/passwordChangeRequest';
import { PasswordResetInitRequest } from '../model/passwordResetInitRequest';
import { PasswordResetTokenRequest } from '../model/passwordResetTokenRequest';
import { UsageLimitsOverview } from '../model/usageLimitsOverview';
import { User } from '../model/user';
import { UserSummary } from '../model/userSummary';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserControllerService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Creates a new entity based on the given data.
     * 
     * @param body 
     * @param ifMatch 
     * @param ifUnmodifiedSince 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(body: User, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public create(body: User, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public create(body: User, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public create(body: User, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling create.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<User>('post',`${this.basePath}/common/users/`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes the profile picture for the specified user id.
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteProfilePicture(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteProfilePicture(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteProfilePicture(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteProfilePicture(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteProfilePicture.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/common/users/${encodeURIComponent(String(id))}/image`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes an entity.
     * 
     * @param id 
     * @param ifMatch 
     * @param ifUnmodifiedSince 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUser(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteUser(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteUser(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteUser(id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteUser.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/common/users/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Read all visible entities. If the user is not allowed to read any entity, an empty list will be returned.
     * 
     * @param offset 
     * @param limit 
     * @param team 
     * @param ifNoneMatch 
     * @param ifModifiedSince 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllUsers(offset?: number, limit?: number, team?: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<Array<LongEntityBase>>;
    public getAllUsers(offset?: number, limit?: number, team?: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LongEntityBase>>>;
    public getAllUsers(offset?: number, limit?: number, team?: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LongEntityBase>>>;
    public getAllUsers(offset?: number, limit?: number, team?: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (team !== undefined && team !== null) {
            queryParameters = queryParameters.set('team', <any>team);
        }

        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }
        if (ifModifiedSince !== undefined && ifModifiedSince !== null) {
            headers = headers.set('If-Modified-Since', String(ifModifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<LongEntityBase>>('get',`${this.basePath}/common/users/`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns a profile picture for the specified user id.
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProfilePicture(id: number, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public getProfilePicture(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public getProfilePicture(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public getProfilePicture(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getProfilePicture.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'image/jpeg'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<string>>('get',`${this.basePath}/common/users/${encodeURIComponent(String(id))}/image`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns an overview of all limits for the given user or current user if none given.
     * 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsageLimitsOverview(userId?: number, observe?: 'body', reportProgress?: boolean): Observable<UsageLimitsOverview>;
    public getUsageLimitsOverview(userId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UsageLimitsOverview>>;
    public getUsageLimitsOverview(userId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UsageLimitsOverview>>;
    public getUsageLimitsOverview(userId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UsageLimitsOverview>('get',`${this.basePath}/common/users/limits`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Read an entity based on the given id.
     * 
     * @param id 
     * @param ifNoneMatch 
     * @param ifModifiedSince 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUser(id: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public getUser(id: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public getUser(id: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public getUser(id: number, ifNoneMatch?: string, ifModifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getUser.');
        }



        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }
        if (ifModifiedSince !== undefined && ifModifiedSince !== null) {
            headers = headers.set('If-Modified-Since', String(ifModifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<User>('get',`${this.basePath}/common/users/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns an overview of all active users known to the given team.
     * 
     * @param search 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserOverview(search?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<UserSummary>>;
    public getUserOverview(search?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserSummary>>>;
    public getUserOverview(search?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserSummary>>>;
    public getUserOverview(search?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (search !== undefined && search !== null) {
            queryParameters = queryParameters.set('search', <any>search);
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<UserSummary>>('get',`${this.basePath}/common/users/overview`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an entity based on the given data.
     * 
     * @param body 
     * @param id 
     * @param ifMatch 
     * @param ifUnmodifiedSince 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public overwriteUser(body: User, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public overwriteUser(body: User, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public overwriteUser(body: User, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public overwriteUser(body: User, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling overwriteUser.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling overwriteUser.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<User>('put',`${this.basePath}/common/users/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates an entity based on the given modifications. The modified entity is returned.
     * 
     * @param body 
     * @param id 
     * @param ifMatch 
     * @param ifUnmodifiedSince 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchUser(body: Array<FieldModification>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public patchUser(body: Array<FieldModification>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public patchUser(body: Array<FieldModification>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public patchUser(body: Array<FieldModification>, id: number, ifMatch?: string, ifUnmodifiedSince?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling patchUser.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling patchUser.');
        }



        let headers = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            headers = headers.set('If-Match', String(ifMatch));
        }
        if (ifUnmodifiedSince !== undefined && ifUnmodifiedSince !== null) {
            headers = headers.set('If-Unmodified-Since', String(ifUnmodifiedSince));
        }

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<User>('patch',`${this.basePath}/common/users/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetPasswordInit(body: PasswordResetInitRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public resetPasswordInit(body: PasswordResetInitRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public resetPasswordInit(body: PasswordResetInitRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public resetPasswordInit(body: PasswordResetInitRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling resetPasswordInit.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/common/users/actions/resetPasswordInit`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetPasswordWithToken(body: PasswordResetTokenRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public resetPasswordWithToken(body: PasswordResetTokenRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public resetPasswordWithToken(body: PasswordResetTokenRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public resetPasswordWithToken(body: PasswordResetTokenRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling resetPasswordWithToken.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/common/users/actions/resetPasswordWithToken`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sets the profile picture for the specified user id.
     * 
     * @param id 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setProfilePicture(id: number, body?: IdImageBody, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setProfilePicture(id: number, body?: IdImageBody, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setProfilePicture(id: number, body?: IdImageBody, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setProfilePicture(id: number, body?: IdImageBody, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setProfilePicture.');
        }


        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/common/users/${encodeURIComponent(String(id))}/image`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePassword(body: PasswordChangeRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updatePassword(body: PasswordChangeRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updatePassword(body: PasswordChangeRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updatePassword(body: PasswordChangeRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updatePassword.');
        }

        let headers = this.defaultHeaders;

        // authentication (apiKey) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["apiKey"]) {
            headers = headers.set('apiKey', this.configuration.apiKeys["apiKey"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/common/users/actions/setPassword`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
