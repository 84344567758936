import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {RememberMeService} from '@common/remember-me.service';
import {UserSessionService} from '@common/user-session.service';
import {UserService} from '@common/user.service';

@Injectable()
export class RememberMeInterceptor implements HttpInterceptor {

  constructor(
    private readonly rememberMeService: RememberMeService,
    private readonly userService: UserService,
    private readonly userSessionService: UserSessionService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          this.handleResponse(event);
        }
      })
    );
  }

  private handleResponse(res: HttpResponse<any>) {
    const sessionToken = res.headers.get('X-Session');
    const rememberMeToken = res.headers.get(this.rememberMeService.rememberMeHeader);

    if (sessionToken && sessionToken !== this.userSessionService.session.apiKey) {
      const sessionInformation = this.userService.sessionInformation.getValue();
      sessionInformation.session.apiKey = sessionToken;
      // sessionInformation.rememberMeToken.token = rememberMeToken;
      this.userService.sessionInformation.next(sessionInformation);
    }

    if (rememberMeToken && rememberMeToken !== this.rememberMeService.getRememberMeToken()) {
      this.rememberMeService.setRememberMeToken(JSON.parse(rememberMeToken));
    }
  }
}
