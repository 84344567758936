<footer class="footer">
  <div class="container">
    <ul>
      <li><a routerLink="/legal/imprint" routerLinkActive="active" translate>page-titles.imprint</a></li>
      <li><a routerLink="/legal/privacy" routerLinkActive="active" translate>page-titles.privacy</a></li>
      <li><a routerLink="/legal/terms" routerLinkActive="active" translate>page-titles.terms</a></li>
      <li class="version" *ngIf="isLoggedIn$ | async"><a (click)="showReleaseNotes()">Version {{version}} {{!prod ? 'dev' : ''}}</a></li>
    </ul>
  </div>
</footer>
